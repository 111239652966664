import { Ability, AbilityBuilder } from '@casl/ability';
import {
  EXTERNAL, MANAGER, OPERATOR, SUPERVISOR
} from '../../constants/jobRoleCategory';
import {
  ROLE_FORWOOD_ID_ADMIN,
  ROLE_SITE_ADMIN,
  ROLE_VERIFICATIONS_ACCESS,
  ROLE_CRM_ACCESS,
  ROLE_CONTENT_ADMIN,
} from '../../constants/roles';
import * as screens from '../../constants/screens';

function grantForwoodIDAdminPermissions(can) {
  can('view', screens.CREATE_MANAGER_CONTROL_CHECKLIST);
  can('view', screens.MANAGER_CONTROL_CHECKLIST_APPROVALS);
  can('view', screens.MANAGER_CONTROL_CHECKLIST_APPROVALS_SETTING);
}

function grantSiteAdminPermissions(can) {
  can('view', 'ManagerMenu');
  can('view', screens.MANAGER_CONTROL_CHECKLIST);
  can('view', screens.MANAGER_VERIFICATION_LIST);
  can('view', screens.PERFORM_MANAGER_VERIFICATION);
}

function grantSupervisorPermissions(can) {
  can('view', 'SupervisorMenu');
  can('view', screens.PERFORM_SUPERVISOR_VERIFICATION);
  can('view', screens.PERFORM_SUPERVISOR_TASK_BASED_VERIFICATION);
  can('view', screens.SUPERVISOR_VERIFICATION_LIST);
}

function grantOperatorPermissions(can) {
  can('view', 'OperatorMenu');
  can('view', screens.PERFORM_OPERATOR_VERIFICATION);
  can('view', screens.PERFORM_OPERATOR_TASK_BASED_VERIFICATION);
  can('view', 'SupervisorMenu');
  can('view', screens.SUPERVISOR_VERIFICATION_LIST);
}

function grantDailyNotifications(can) {
  can('view', 'DailyNotificationsMenu');
  can('view', screens.CREATE_DAILY_NOTIFICATION);
  can('view', screens.DAILY_NOTIFICATION_LIST);
}

function grantVerificationSchedule(can) {
  can('view', 'VerificationScheduleMenu');
  can('view', screens.VERIFICATION_SCHEDULE);
  can('view', screens.VERIFICATION_SCHEDULE_MANAGEMENT);
}

function grantReportPermissions(can) {
  can('view', 'ReportsMenu');
  can('view', screens.REPORT_SUBMISSION_TOTALS);
  can('view', screens.REPORT_STANDARD_REPORT);
  can('view', screens.REPORT_VERIFICATION_SCHEDULER_REPORTS);
  can('view', screens.REPORT_ACTION_PLAN_STATUS);
  can('view', screens.REPORT_VERIFICATION_PARTICIPATION_SUMMARY);
}

function grantContentAdminPermissions(can) {
  can('view', 'SupervisorOperatorRiskTaskBasedChecklist');
  can('view', 'CreateSupervisorOperatorTaskBasedChecklist');
}

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // this one is required
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function updateAbility(currentAbility, decodedIdToken) {
  const { can, rules } = new AbilityBuilder();

  if (
    !decodedIdToken
    || !decodedIdToken['cognito:groups']
    || !decodedIdToken['cognito:groups'].length
  ) {
    return;
  }

  const cognitoGroups = decodedIdToken['cognito:groups'];
  const jobRoleCategory = decodedIdToken.job_role_category;

  if (![ROLE_VERIFICATIONS_ACCESS, ROLE_CRM_ACCESS].every((role) => cognitoGroups.includes(role))) {
    return;
  }

  can('view', 'HomePage');

  if (cognitoGroups.includes(ROLE_FORWOOD_ID_ADMIN) && jobRoleCategory === MANAGER) {
    grantForwoodIDAdminPermissions(can);
    grantSiteAdminPermissions(can);
    grantSupervisorPermissions(can);
    grantOperatorPermissions(can);
  }

  if (
    jobRoleCategory === MANAGER
    || [ROLE_SITE_ADMIN].every((role) => cognitoGroups.includes(role))
  ) {
    grantSiteAdminPermissions(can);
    grantSupervisorPermissions(can);
    grantOperatorPermissions(can);
    grantDailyNotifications(can);
    grantVerificationSchedule(can);
  }

  if (jobRoleCategory === MANAGER) {
    grantReportPermissions(can);
  }

  if (jobRoleCategory === SUPERVISOR) {
    grantSupervisorPermissions(can);
    grantOperatorPermissions(can);
  }

  if ([OPERATOR, EXTERNAL].includes(jobRoleCategory)) {
    grantOperatorPermissions(can);
  }

  if (cognitoGroups.includes(ROLE_CONTENT_ADMIN)) {
    grantContentAdminPermissions(can);
  }

  currentAbility.update(rules);
}

export default ability;
