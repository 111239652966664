import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import Home from './Containers/Home';
import PerformManagerVerification from './Containers/PerformManagerVerification';
import ManagerVerificationList from './Containers/ManagerVerificationList';
import ManagerControlChecklist from './Containers/ManagerControlChecklist';
import CreateManagerControlChecklist from './Containers/CreateManagerControlChecklist';
import ManagerControlChecklistApprovals from './Containers/ManagerControlChecklistApprovals';
import ManagerControlChecklistApprovalsSetting from './Containers/ManagerControlChecklistApprovalsSetting';
import PerformSupervisorVerification from './Containers/PerformSupervisorVerification';
import PerformSupervisorTaskBasedVerification from './Containers/PerformSupervisorTaskBasedVerification';
import PerformOperatorVerification from './Containers/PerformOperatorVerification';
import PerformOperatorTaskBasedVerification from './Containers/PerformOperatorTaskBasedVerification';
import SupervisorVerificationList from './Containers/SupervisorVerificationList';
import CreateDailyNotification from './Containers/CreateDailyNotification';
import DailyNotificationList from './Containers/DailyNotificationList';
import VerificationSchedule from './Containers/VerificationSchedule';
import VerificationScheduleManagement from './Containers/VerificationScheduleManagement';
import ReportSubmissionTotals from './Containers/ReportSubmissionTotals';
import ReportStandardReport from './Containers/ReportStandardReport';
import ReportVerificationSchedulerReports from './Containers/ReportVerificationSchedulerReports';
import ReportActionPlanStatus from './Containers/ReportActionPlanStatus';
import ReportVerificationParticipationSummary from './Containers/ReportVerificationParticipationSummary';
import SupervisorOperatorRiskTaskBasedChecklist from './Containers/SupervisorOperatorRiskTaskBasedChecklist';
import CreateSupervisorOperatorTaskBasedChecklist from './Containers/CreateSupervisorOperatorTaskBasedChecklist';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </>
);

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routes = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/perform-manager-verification',
        exact: true,
        component: PerformManagerVerification,
      },
      {
        path: '/manager-control-checklist',
        exact: true,
        component: ManagerControlChecklist,
      },
      {
        path: '/manager-verification-list',
        exact: true,
        component: ManagerVerificationList,
      },
      {
        path: '/create-manager-control-checklist',
        exact: true,
        component: CreateManagerControlChecklist,
      },
      {
        path: '/manager-control-checklist-approvals',
        exact: true,
        component: ManagerControlChecklistApprovals,
      },
      {
        path: '/manager-control-checklist-approvals-setting',
        exact: true,
        component: ManagerControlChecklistApprovalsSetting,
      },
      {
        path: '/supervisor-perform-verification',
        exact: true,
        component: PerformSupervisorVerification,
      },
      {
        path: '/supervisor-perform-task-based-verification',
        exact: true,
        component: PerformSupervisorTaskBasedVerification,
      },
      {
        path: '/supervisor-verification-list',
        exact: true,
        component: SupervisorVerificationList,
      },
      {
        path: '/operator-perform-verification',
        exact: true,
        component: PerformOperatorVerification,
      },
      {
        path: '/operator-perform-task-based-verification',
        exact: true,
        component: PerformOperatorTaskBasedVerification,
      },
      {
        path: '/create-daily-notification',
        exact: true,
        component: CreateDailyNotification,
      },
      {
        path: '/daily-notification-list',
        exact: true,
        component: DailyNotificationList,
      },
      {
        path: '/verification-schedule',
        exact: true,
        component: VerificationSchedule,
      },
      {
        path: '/verification-schedule-management',
        exact: true,
        component: VerificationScheduleManagement,
      },
      {
        path: '/mrm-statistics/ccps/statistics/submission-totals',
        exact: true,
        component: ReportSubmissionTotals,
      },
      { path: '/mat_risk_standard_report', exact: true, component: ReportStandardReport },
      {
        path: '/verification_scheduler_reports',
        exact: true,
        component: ReportVerificationSchedulerReports,
      },
      { path: '/action-plan-status', exact: true, component: ReportActionPlanStatus },
      {
        path: '/verification-participation-summary',
        exact: true,
        component: ReportVerificationParticipationSummary,
      },
      {
        path: '/crc-list',
        exact: true,
        component: SupervisorOperatorRiskTaskBasedChecklist,
      },
      {
        path: '/node/add/crc',
        exact: true,
        component: CreateSupervisorOperatorTaskBasedChecklist,
      },
      // {
      //   path: '/id-homepage',
      //   exact: true,
      //   component: IDHomepage,
      // },
    ],
  },
];

Root.propTypes = {
  route: PropTypes.object.isRequired,
};
