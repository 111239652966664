import Config from '../Config';
import * as screens from '../constants/screens';

const IFRAME_HISTORY_CHANGE_URL = 'IFRAME_HISTORY_CHANGE_URL';
const IFRAME_HISTORY_CURRENT_SCREEN = 'IFRAME_HISTORY_CURRENT_SCREEN';

export function iframeHistoryDefaultState() {
  return {
    currentScreen: null,
    [screens.HOME_PAGE]: Config.reactApp.CRM_HOMEPAGE_URL,
    // Manager Menu
    [screens.PERFORM_MANAGER_VERIFICATION]: Config.reactApp.PERFORM_MANAGER_VERIFICATION_URL,
    [screens.MANAGER_CONTROL_CHECKLIST]: Config.reactApp.MANAGER_CONTROL_CHECKLIST_URL,
    [screens.MANAGER_VERIFICATION_LIST]: Config.reactApp.MANAGER_VERIFICATION_LIST_URL,
    // Supervisor Menu
    [screens.PERFORM_SUPERVISOR_VERIFICATION]: Config.reactApp.PERFORM_SUPERVISOR_VERIFICATION_URL,
    [screens.PERFORM_SUPERVISOR_TASK_BASED_VERIFICATION]: Config.reactApp.PERFORM_SUPERVISOR_TASK_BASED_VERIFICATION_URL,
    [screens.SUPERVISOR_VERIFICATION_LIST]: Config.reactApp.SUPERVISOR_VERIFICATION_LIST_URL,
    [screens.CREATE_SUPERVISOR_OPERATOR_RISK_TBC]: Config.reactApp.CREATE_SUPERVISOR_OPERATOR_TB_CHECKLIST_URL,
    [screens.SUPERVISOR_OPERATOR_RISK_TBC]: Config.reactApp.SUPERVISOR_OPERATOR_RISK_TB_CHECKLIST_URL,
    // Operator Menu
    [screens.PERFORM_OPERATOR_VERIFICATION]: Config.reactApp.PERFORM_OPERATOR_VERIFICATION_URL,
    [screens.PERFORM_OPERATOR_TASK_BASED_VERIFICATION]: Config.reactApp.PERFORM_OPERATOR_TASK_BASED_VERIFICATION_URL,
    // Notification Menu
    [screens.CREATE_DAILY_NOTIFICATION]: Config.reactApp.CREATE_DAILY_NOTIFICATION_URL,
    [screens.DAILY_NOTIFICATION_LIST]: Config.reactApp.DAILY_NOTIFICATION_LIST_URL,
    // Verification Schedule Menu
    [screens.VERIFICATION_SCHEDULE]: Config.reactApp.VERIFICATION_SCHEDULE_URL,
    [screens.VERIFICATION_SCHEDULE_MANAGEMENT]: Config.reactApp.VERIFICATION_SCHEDULE_MANAGEMENT_URL,
    // Reports Menu
    [screens.REPORT_SUBMISSION_TOTALS]: Config.reactApp.REPORT_SUBMISSION_TOTALS_URL,
    [screens.REPORT_STANDARD_REPORT]: Config.reactApp.REPORT_STANDARD_REPORT_URL,
    [screens.REPORT_VERIFICATION_SCHEDULER_REPORTS]: Config.reactApp.REPORT_VERIFICATION_SCHEDULER_REPORTS_URL,
    [screens.REPORT_VERIFICATION_PARTICIPATION_SUMMARY]: Config.reactApp.REPORT_VERIFICATION_PARTICIPATION_SUMMARY_URL,
    [screens.REPORT_ACTION_PLAN_STATUS]: Config.reactApp.REPORT_ACTION_PLAN_STATUS_URL,
  };
}

export function setCurrentScreen(dispatch, payload) {
  dispatch({
    type: IFRAME_HISTORY_CURRENT_SCREEN,
    payload
  });
}

export function setIframeHistoryUrl(dispatch, { name, url }) {
  dispatch({
    type: IFRAME_HISTORY_CHANGE_URL,
    payload: { name, url }
  });
}

export default (state = iframeHistoryDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case IFRAME_HISTORY_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload
      };

    case IFRAME_HISTORY_CHANGE_URL:
      return {
        ...state,
        [payload.name]: payload.url
      };

    default:
      return state;
  }
};
